
import { defineComponent, ref } from 'vue';
import { IMatch } from '@/api/match/match.api';
import { HubConnectionBuilder, HubConnection, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import { useMsal } from '@/composition-api/useMsal';
import { loginRequest } from '@/authConfig';

export default defineComponent({
  name: 'MatchView',
  setup() {
    return {
      matches: ref([] as IMatch[]),
      signalRConnection: {} as HubConnection,
      competitionId: 2013,
      seasonId: 1557,
    };
  },
  methods: {
    async initSignalRConnection() {
      const msalContext = useMsal();
      const response = await msalContext.instance
        .acquireTokenSilent({ ...loginRequest, account: msalContext.accounts.value[0] })
        .catch(async (error) => {
          // If user is not logged in redirect to login page
          msalContext.instance.loginRedirect(loginRequest);
          throw error;
        });
      const accessToken = response.idToken;
      this.signalRConnection = new HubConnectionBuilder()
        .withUrl('/api', { accessTokenFactory: () => accessToken })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Error)
        .build();
      const matchUpdated = (...args: any) => {
        const currentMatch = args[0].Match as IMatch;
        if (currentMatch.SeasonId != this.seasonId || currentMatch.CompetitionId != this.competitionId) {
          return;
        }
        const index = this.matches.findIndex((match) => match.Id === currentMatch.Id);

        if (index !== -1) {
          this.matches.splice(index, 1, currentMatch);
        } else {
          this.matches.push(currentMatch);
        }
      };
      this.signalRConnection.on('updatedMatch', matchUpdated);
    },
  },
  async mounted() {
    await this.initSignalRConnection();
    this.matches = await this.$api.match.listMatches(this.competitionId, this.seasonId);
    console.log(this.matches);
    this.signalRConnection.start();
  },
  async beforeUnmount() {
    this.signalRConnection.stop();
  },
});
