import Vuex from 'vuex';
import user from './modules/user.store';

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user,
  },
  strict: debug,
});
