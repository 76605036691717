import { App, reactive } from 'vue';

export default {
  install: (app: App, options: any): void => {
    app.config.globalProperties.$toaster = {
      state: reactive({
        count: 0,
        toasts: [],
      }),
      addToast(message: string, options?: { color: string; header: string; autohide: number }) {
        app.config.globalProperties.$toaster.state.toasts.push({
          key: app.config.globalProperties.$toaster.state.count,
          message,
          color: options?.color,
          header: options?.header,
          autohide: options?.autohide ?? true,
          timestamp: new Date(),
        });
        app.config.globalProperties.$toaster.state.count++;
      },
    };
  },
};
