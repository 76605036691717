import { App } from 'vue';
import * as components from './components';

const lib = {
  install: (app: App): void => {
    Object.entries(components).forEach(([compoentName, component]) => {
      app.component(compoentName, component);
    });
  },
};

export default lib;
