import axios, { AxiosInstance } from 'axios';
import { IBaseModel } from '../apiBaseModel';

export default (axiosClient: AxiosInstance, apiPath: string) => ({
  async listAddresses(): Promise<IAddress[]> {
    return (await axiosClient.get(`${apiPath}`)).data;
  },
  async getAddress(addressId: string): Promise<IAddress> {
    return (await axiosClient.get(`${apiPath}/${addressId}`)).data;
  },
});

export interface IAddress extends IBaseModel {
  Name: string;
  LastName: string;
  Street: string;
  HouseNumber: string;
  Zip: string;
  City: string;
}
