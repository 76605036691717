
import { defineComponent } from 'vue';
import { IToast } from '@/types/interfaces';

export default defineComponent({
  name: 'CToast',
  props: {
    toast: Object,
  },
});
