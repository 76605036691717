import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Page404 from '../views/errorPages/404Page.vue';

import HomeView from '../views/HomeView.vue';
import AddressView from '../views/AddressView.vue';
import MatchView from '../views/MatchView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: Page404,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/address',
    name: 'address',
    component: AddressView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/match',
    name: 'match',
    component: MatchView,
    meta:
    {
      requiresAuth: true,
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
