
import { defineComponent } from 'vue';
import HeaderContainer from './components/HeaderContainer.vue';
import { setResponseInterceptor, setRequestInterceptor } from './api/interceptors';
import { useMsal } from '@/composition-api/useMsal';

export default defineComponent({
  name: 'App',
  components: {
    HeaderContainer,
  },
  setup() {
    return {
      msalContext: useMsal(),
    };
  },
  watch: {
    '$i18n.locale'(val) {
      // Set "lang" attribute to html tag (WCAG 3.1.1/3.1.2)
      document.documentElement.setAttribute('lang', val);
    },
  },
  async beforeCreate() {
    // Initialize api interceptors
    setResponseInterceptor(this);
    setRequestInterceptor(this, this.msalContext);
  },
  created() {
    // Set "lang" attribute to html tag (WCAG 3.1.1/3.1.2)
    document.documentElement.setAttribute('lang', this.$i18n.locale);
  },
});
