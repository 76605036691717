import axios from 'axios';
import { App } from 'vue';

// Import API factiories

// User
import Address from './address/address.api';
import Match from './match/match.api'
// Create axios instance
export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create factories object
export const factories = {
  address: Address(httpClient, '/address'),
  match:Match(httpClient,'/match')
};

// Define HttpClient install function
const HttpClient = {
  install: (app: App): void => {
    app.config.globalProperties.$http = httpClient;
    app.config.globalProperties.$api = factories;
  },
};

export default HttpClient;
