
import { IMatch } from '@/api/match/match.api';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'HomeView',
  data() {
    return { matches: ref([] as IMatch[]) };
  },
  async mounted() {
    console.log(this.$i18n);
  },
});
