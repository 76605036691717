import axios, { AxiosInstance } from 'axios';
import { IBaseModel } from '../apiBaseModel';

export default (axiosClient: AxiosInstance, apiPath: string) => ({
  async listMatches(competitionId: Number, seasonId: number): Promise<IMatch[]> {
    return (await axiosClient.get(`${apiPath}/${competitionId}/${seasonId}`)).data;
  },
});
export interface IMatch extends IBaseModel {
  SeasonId: number;
  CompetitionId: number;
  Home: ICompetitor;
  Away: ICompetitor;
  UTCDate: Date;
  MatchDay: number;
  Odds: IOdds;
  Status: MatchStatus;
}
interface ICompetitor {
  Id: string | null;
  Name: string | null;
  ShortName: string | null;
  Tla: string | null;
  Crest: string | null;
  Score: number | null;
}
export interface IOdds {
  HomeWin: number;
  Draw: number;
}
export enum MatchStatus { }
