
import { defineComponent, reactive } from 'vue';
import { IToast } from '@/types/interfaces';

export default defineComponent({
  name: 'CToaster',
  setup() {
    return {
      toasts: reactive([] as IToast[]),
      dateFormat: {
        minute: '2-digit',
        hour: '2-digit',
      },
    };
  },
  watch: {
    '$toaster.state.count'(value: number) {
      this.addToast(value);
    },
  },
  methods: {
    addToast(value: number) {
      // Show all toasts which are recently added
      const newToastsCount = value - this.toasts.length;
      for (let i = newToastsCount; i > 0; i--) {
        this.toasts.push(this.$toaster.state.toasts[value - i]);
      }
    },
  },
});
