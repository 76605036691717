
import { defineComponent } from 'vue';
import { useMsal } from '@/composition-api/useMsal';
import { loginRequest } from '@/authConfig';

export default defineComponent({
  name: 'LoginButton',
  setup() {
    return {
      msalContext: useMsal(),
    };
  },
  methods: {
    loginRedirect() {
      this.msalContext.instance.loginRedirect(loginRequest);
    },
  },
});
