import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import { msalPlugin } from './plugins/msalPlugin';
import { msalInstance } from './authConfig';
import { CustomNavigationClient } from './router/NavigationClient';
import router from './router';
import store from './store';
import httpClient from './api/httpClient';
import toaster from './plugins/toaster';
import { i18n } from './utils/i18n';
import lib from './components/lib';

const app = createApp(App);

app.config.globalProperties.$msalInstance = {};
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

app.use(lib);
app.use(toaster);
app.use(httpClient);
app.use(i18n);
app.use(router);
app.use(store);
app.use(msalPlugin, msalInstance);

app.mount('#app');
