
import { defineComponent } from 'vue';
import { useMsal } from '@/composition-api/useMsal';

export default defineComponent({
  name: 'SignOutButton',
  setup() {
    return {
      msalContext: useMsal(),
    };
  },
  methods: {
    logoutRedirect() {
      this.msalContext.instance.logoutRedirect();
    },
  },
});
