import { createI18n } from 'vue-i18n';

function setupI18n() {
  const locale = process.env.VUE_APP_I18N_LOCALE || 'de-DE';
  const messages = loadLocaleMessages();
  const i18n = createI18n({
    locale,
    messages,
  });
  return i18n;
}

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: any = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const i18n = setupI18n();
