import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ComponentPublicInstance } from 'vue';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from '@/authConfig';
import { MsalContext } from '@/composition-api/useMsal';

/**
 * Setup all Response Interceptors to run additional code on every http response
 * @param VueInstance Instance of the current Vue application
 */
export function setResponseInterceptor(VueInstance: ComponentPublicInstance) {
  const responseInterceptor = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  const errorInterceptor = (error: AxiosError) => {
    // check if it's a server error
    if (!error.response) {
      // notify.warn('Network/Server error');
      return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        // notify.warn('Nothing to display','Data Not Found');
        break;
      default:
        console.error(error.response.status, error.message);
    }
    throw error.response.data;
  };

  // Remove old interceptors (e.g. from multiple loadings)
  VueInstance.$http.interceptors.response.eject(responseInterceptor as any);
  VueInstance.$http.interceptors.response.eject(errorInterceptor as any);
  VueInstance.$http.interceptors.response.use(responseInterceptor, errorInterceptor);
}

/**
 * Setup all Request Interceptors to run additional code on every http request
 * @param VueInstance Instance of the current Vue application
 */
export function setRequestInterceptor(VueInstance: ComponentPublicInstance, msalContext: MsalContext) {
  const requestInterceptor = async (config: AxiosRequestConfig) => {
    config.headers!['Authorization'] = await getAccessToken(msalContext);
    config.headers!['User-Language'] = VueInstance.$i18n.locale;
    return config;
  };

  // Remove old interceptors (e.g. from multiple loadings)
  VueInstance.$http.interceptors.request.eject(requestInterceptor as any);
  VueInstance.$http.interceptors.request.use(requestInterceptor);
}

async function getAccessToken(msalContext: MsalContext): Promise<string> {
  const account = msalContext.accounts.value[0];
  const response = await msalContext.instance.acquireTokenSilent({ ...loginRequest, account }).catch(async (error) => {
    // If user is not logged in redirect to login page
    msalContext.instance.loginRedirect(loginRequest);
    throw error;
  });
  return 'Bearer ' + response.idToken;
}
