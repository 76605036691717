
import { IAddress } from '@/api/address/address.api';
import { defineComponent, reactive, ref } from 'vue';

export default defineComponent({
  name: 'AddressView',
  setup() {
    return {
      addresses: ref([] as IAddress[]),
      address: reactive({}),
    };
  },
  async mounted() {
    this.addresses = await this.$api.address.listAddresses();
  },
});
