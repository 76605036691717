
import { defineComponent, computed } from 'vue';
import { useIsAuthenticated } from '../composition-api/useIsAuthenticated';
import { useMsal } from '@/composition-api/useMsal';

export default defineComponent({
  name: 'HeaderContainer',
  setup() {
    const { accounts } = useMsal();
    const name = computed(() => {
      if (accounts.value.length > 0) {
        const name = accounts.value[0].name;
        if (name) {
          return name.split(' ')[0];
        }
      }
      return '';
    });
    return {
      isAuthenticated: useIsAuthenticated(),
      name,
    };
  },
});
