/* eslint-disable @typescript-eslint/no-explicit-any */

// This is just a store example and not used in this application!!!
const state = () => ({
  user: {},
  accessToken: '',
});

const getters = {
  getUser: (state: any, getters: any, rootState: any) => {
    return state.user;
  },
  getAccessToken: (state: any, getters: any, rootState: any) => {
    return state.accessToken;
  },
};

const mutations = {
  setUser(state: any, user: any) {
    state.user = user;
  },
  setAccessToken(state: any, accessToken: string) {
    state.accessToken = accessToken;
  },
  logout(state: any) {
    state.user = {};
    state.accessToken = '';
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
